const getElement = (el): HTMLElement => {
  if (el.classList.contains('el-input')) {
    return el.getElementsByClassName('el-input__inner')[0];
  }
  if (el.classList.contains('el-select')) {
    return el.getElementsByClassName('el-select__wrapper')[0];
  }
  return el;
};

const focusDirective = app => {
  if (!app?.directive) return;

  app.directive('focus', {
    mounted(el, binding) {
      if (!('value' in binding) || binding.value) {
        getElement(el).focus();
      }
    },
  });
};

export default focusDirective;
